/*--------------------------------------------------------------
  Select2
  Override stuff in ../../../bower_components/select2/src/scss/core.scss
--------------------------------------------------------------*/

/*--------------------------------------------------------------
  CORE
--------------------------------------------------------------*/

.select2 {
    width: 100% !important;
}

.select2,
.select2-selection {
    &:focus {
        outline: none;
    }
}

.select2-container,
.select2-container--default {
    width: 100% !important;
    white-space: nowrap !important;
    //   position: relative;
    //   display: inline-block;
    //   box-sizing: border-box;
    //   margin: 0;
    //   vertical-align: middle;
    // /*--------------------------------------------------------------
    //   CORE SINGLE
    // --------------------------------------------------------------*/
    .select2-selection--single {
        //     display: block;
        //     box-sizing: border-box;
        height: 36px;
        background-color: $white;
        background-image: linear-gradient($white, shade($white, 5%));
        //     cursor: pointer;
        //     -webkit-user-select: none;
        //             user-select: none;
        .select2-selection__rendered {
            //       display: block;
            //       overflow: hidden;
            padding-right: $spacing-unit;
            padding-left: $spacing-unit / 2;
            //       white-space: nowrap;
            //       text-overflow: ellipsis;
        }
    }
    &[dir='rtl'] {
        .select2-selection--single {
            .select2-selection__rendered {
                padding-right: $spacing-unit / 2;
                padding-left: $spacing-unit;
            }
        }
    }
    // /*--------------------------------------------------------------
    //   CORE MULTIPLE
    // --------------------------------------------------------------*/
    .select2-selection--multiple {
        display: block;
        box-sizing: border-box;
        min-height: 36px;
        cursor: pointer;
        user-select: none;
        transition: $transition-speed $transition-easing;
        border: 1px solid $iron;
        border-top-color: $base;
        border-radius: $radius;
        box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.1);

        -webkit-appearance: none;
        &:hover {
            border-color: shade($iron, 20%);
        }
        .select2-search__field {
            font-family: $font-stack;
            font-size: $font-size;
        }
        .select2-selection__rendered {
            display: inline-block;
            overflow: hidden;
            padding: 3px 6px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .select2-selection__choice {
            margin-top: 3px;
            border: 1px solid $iron;
            border-radius: $radius;
            background-color: $white;
            background-image: linear-gradient($white, shade($white, 5%));
        }
        .select2-search--inline {
            .select2-search__field {
                width: 100% !important;
            }
        }
    }
    //   .select2-search--inline {
    //     float: left;
    //     .select2-search__field {
    //       font-size: 100%;
    //       box-sizing: border-box;
    //       margin-top: 5px;
    //       border: none;
    //       &::-webkit-search-cancel-button {
    //         -webkit-appearance: none;
    //       }
    //     }
    //   }
}

/*--------------------------------------------------------------
  DROPDOWN
--------------------------------------------------------------*/
.select2-dropdown {
    //   position: absolute;
    //   z-index: 1051;
    //   left: -100000px;
    //   display: block;
    //   box-sizing: border-box;
    //   width: 100%;
    border: 1px solid $iron;
    border-radius: $radius;
    //   background-color: white;
}

// .select2-results {
//   display: block;
// }

// .select2-results__options {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

.select2-results__option {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
    //   -webkit-user-select: none;
    //           user-select: none;
    //   &[aria-selected] {
    //     cursor: pointer;
    //   }
}

// .select2-container--open .select2-dropdown {
//   left: 0;
// }

.select2-container--open .select2-dropdown--above {
    //   border-bottom: none;
    //   border-bottom-right-radius: 0;
    //   border-bottom-left-radius: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.select2-container--open .select2-dropdown--below {
    //   border-top: none;
    //   border-top-left-radius: 0;
    //   border-top-right-radius: 0;
    box-shadow: $box-shadow;
}

.select2-search--dropdown {
    //   display: block;
    //   padding: 4px;
    .select2-search__field {
        //     &::-webkit-search-cancel-button {
        //       -webkit-appearance: none;
        //     }
        font-family: $font-stack;
        font-size: $font-size;
        //     box-sizing: border-box;
        //     width: 100%;
        padding: 8px 4px;
        border-radius: $radius;
        &:focus {
            outline: none;
        }
    }
    //   &.select2-search--hide {
    //     display: none;
    //   }
}


// .select2-close-mask {
//   position: fixed;
//   z-index: 99;
//   top: 0;
//   left: 0;
//   display: block;
//   width: auto;
//   min-width: 100%;
//   height: auto;
//   min-height: 100%;
//   margin: 0;
//   padding: 0;
//   opacity: 0;
//   border: 0;
//   // styles required for IE to work
//   background-color: #fff;

//   filter: alpha(opacity=0);
// }


/*--------------------------------------------------------------
  THEME
--------------------------------------------------------------*/

.select2-container--default {
    /**
   * SINGLE
   */
    .select2-selection--single {
        border: 1px solid $iron;
        border-radius: $radius;
        // background-color: $white;
        .select2-selection__rendered {
            line-height: 35px;
            color: $charcoal;
        }
        //     .select2-selection__clear {
        //       font-weight: bold;
        //       float: right;
        //       cursor: pointer;
        //     }
        .select2-selection__placeholder {
            color: $oil;
        }
        .select2-selection__arrow {
            //       position: absolute;
            top: 0;
            right: 11px;
            //       width: 20px;
            height: 36px;
            b {
                //         position: absolute;
                //         top: 50%;
                //         left: 50%;
                //         width: 0;
                //         height: 0;
                //         margin-top: -2px;
                //         margin-left: -4px;
                //         border-width: 5px 4px 0 4px;
                //         border-style: solid;
                border-color: $iron transparent transparent transparent;
            }
        }
    }
    //   &[dir='rtl'] {
    //     .select2-selection--single {
    //       .select2-selection__clear {
    //         float: left;
    //       }
    //       .select2-selection__arrow {
    //         right: auto;
    //         left: 1px;
    //       }
    //     }
    //   }
    //   &.select2-container--disabled {
    //     .select2-selection--single {
    //       cursor: default;
    //       background-color: #eee;
    //       .select2-selection__clear {
    //         display: none;
    //       }
    //     }
    //   }
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    margin-top: -3px;
                    // border-width: 0 4px 5px 4px;
                    border-color: transparent transparent $iron transparent !important;
                }
            }
        }
    }
    //   /**
    //    * MULTIPLE
    //    */
    //   .select2-selection--multiple {
    //     cursor: text;
    //     border: 1px solid #aaa;
    //     border-radius: 4px;
    //     background-color: white;
    //     .select2-selection__rendered {
    //       box-sizing: border-box;
    //       width: 100%;
    //       margin: 0;
    //       padding: 0 5px;
    //       list-style: none;
    //     }
    //     .select2-selection__placeholder {
    //       float: left;
    //       margin-top: 5px;
    //       color: #999;
    //     }
    //     .select2-selection__clear {
    //       font-weight: bold;
    //       float: right;
    //       margin-top: 5px;
    //       margin-right: 10px;
    //       cursor: pointer;
    //     }
    //     .select2-selection__choice {
    //       float: left;
    //       margin-top: 5px;
    //       margin-right: 5px;
    //       padding: 0 5px;
    //       cursor: default;
    //       border: 1px solid #aaa;
    //       border-radius: 4px;
    //       background-color: #e4e4e4;
    //     }
    //     .select2-selection__choice__remove {
    //       font-weight: bold;
    //       display: inline-block;
    //       margin-right: 2px;
    //       cursor: pointer;
    //       color: #999;
    //       &:hover {
    //         color: #333;
    //       }
    //     }
    //   }
    //   &[dir='rtl'] {
    //     .select2-selection--multiple {
    //       .select2-selection__choice,
    //       .select2-selection__placeholder {
    //         float: right;
    //       }
    //       .select2-selection__choice {
    //         margin-right: auto;
    //         margin-left: 5px;
    //       }
    //       .select2-selection__choice__remove {
    //         margin-right: auto;
    //         margin-left: 2px;
    //       }
    //     }
    //   }
    &.select2-container--focus {
        .select2-selection {
            // border: 0;
            // margin-top: -1px;
            border-color: $primary;
            box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.1), 0 0 8px rgba(55,188,186,0.75);
        }
        // .select2-selection--multiple {
        //   border: solid black 1px;
        //   outline: 0;
        // }
    }
    //   &.select2-container--disabled {
    //     .select2-selection--multiple {
    //       cursor: default;
    //       background-color: #eee;
    //     }
    //     .select2-selection__choice__remove {
    //       display: none;
    //     }
    //   }
    //   /**
    //    * LAYOUT
    //    */
    //   &.select2-container--open.select2-container--above {
    //     .select2-selection--single,
    //     .select2-selection--multiple {
    //       border-top-left-radius: 0;
    //       border-top-right-radius: 0;
    //     }
    //   }
    //   &.select2-container--open.select2-container--below {
    //     .select2-selection--single,
    //     .select2-selection--multiple {
    //       border-bottom-right-radius: 0;
    //       border-bottom-left-radius: 0;
    //     }
    //   }
    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid $iron;
        }
    }
    //   .select2-search--inline {
    //     .select2-search__field {
    //       border: none;
    //       outline: 0;
    //       background: transparent;
    //     }
    //   }
    //   .select2-results > .select2-results__options {
    //     overflow-y: auto;
    //     max-height: 200px;
    //   }
    .select2-results__option {
        //     &[role=group] {
        //       padding: 0;
        //     }
        //     &[aria-disabled=true] {
        //       color: #999;
        //     }
        &[aria-selected=true] {
            font-weight: bold;
            color: $oil;
            background-color: $primary--light;
        }
        //     .select2-results__option {
        //       padding-left: 1em;
        //       .select2-results__group {
        //         padding-left: 0;
        //       }
        //       .select2-results__option {
        //         margin-left: -1em;
        //         padding-left: 2em;
        //         .select2-results__option {
        //           margin-left: -2em;
        //           padding-left: 3em;
        //           .select2-results__option {
        //             margin-left: -3em;
        //             padding-left: 4em;
        //             .select2-results__option {
        //               margin-left: -4em;
        //               padding-left: 5em;
        //               .select2-results__option {
        //                 margin-left: -5em;
        //                 padding-left: 6em;
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
    }
    .select2-results__option--highlighted[aria-selected] {
        color: $oil;
        background-color: $primary--light;
    }
    //   .select2-results__group {
    //     display: block;
    //     padding: 6px;
    //     cursor: default;
    //   }
}
